import {Injectable} from '@angular/core'
import {TimeNavigationService} from '../time-navigation.service'
import {hasPermission, Permission, User, UserService} from './user.service'
import {
  mergeDataWithModifiableObject,
  ModifiableDataObject,
  ModifiableField,
  modifyModifiableObject,
  Synchronizer
} from '../../util/synchronizer'
import {LocalUserPropertiesService} from '../local-user-properties.service'
import {Month, SubmitMonthService, SubmitType, UserAuditorResult} from './submit-month.service'
import {BehaviorSubject, Observable} from 'rxjs'
import {
  ConfirmDialogComponent,
  ConfirmDialogData
} from '../../shared-components/confirm-dialog/confirm-dialog.component'
import {MatDialog} from '@angular/material/dialog'
import {deepCopy} from '../../util/copy'
import {VerifyFilterGlobalsService, VerifyView} from "../verify-filter-globals-service";
import {SynchronizationStatus} from '../sync/synchronize-data.service';

const TIMEBOX_LOCAL_MONTHSUBMITTED_ENTRIES = 'TimeboxLocalMonthEntriesV4'

@Injectable({
  providedIn: 'root'
})
export class LocalSubmitMonthAllUsersService extends Synchronizer<LocalMonth> {

  constructor(private timeNavigationService: TimeNavigationService,
              private submitMonthService: SubmitMonthService,
              private userService: UserService,
              private userPropertiesService: LocalUserPropertiesService,
              private dialog: MatDialog,
              private verifyFilterGlobalsService: VerifyFilterGlobalsService) {
    super()

  }

  private allMonthSubmittedSubject$ = new BehaviorSubject<Month[]>(undefined)
  private synchronizationStateSubject = new BehaviorSubject<SynchronizationStatus>(SynchronizationStatus.CURRENTLY_SYNCHRONIZING)

  updateSyncDataAfterSearch(result: Month[] = []): void {
    this.dataArray = [];
    if (result?.length) {
      result.forEach(res => {
        this.dataArray.push(toLocalMonth(res))
      })
    }
    this.allMonthSubmittedSubject$.next(result)
  }

  get allMonthSubmitted$(): Observable<Month[]> {
    return this.allMonthSubmittedSubject$
  }

  get currentMonth(): Month[] {
    return this.allMonthSubmittedSubject$.getValue()
  }

  // TODO Überlegen wir wir das besser machen können.. ohne Observable$
  getAllStoredMonthSubmitted(): Month[] {
    return this.dataArray.map(toMonth)
  }


  setMonthEntry(month: string, userFK: number, newValue: Month): void {
    const monthEntry = this.dataArray.find((entry) => {
      return entry.month === month && entry.userFK === userFK
    })
    if (monthEntry !== undefined) {
      this.modifyLocalMonthEntry(monthEntry, newValue)
    } else {
      if (newValue != null) {
        const newLocalMonth: LocalMonth = toLocalMonth(newValue, false)
        newLocalMonth.type.value = null
        newLocalMonth.comment.value = null
        newLocalMonth.note.value = null
        newLocalMonth.auditors.value = null
        this.dataArray.push(newLocalMonth)

        this.modifyLocalMonthEntry(newLocalMonth, newValue)
      }
    }

    this.updateListenersForCurrentMonthObservable()
  }


  removeAllByPredicate(predicate: (entry: Month) => boolean): void {
    this.dataArray
      .filter(localEntry => predicate(toMonth(localEntry)))
      .forEach((localEntry) => {
        this.modifyLocalMonthEntry(localEntry, {
          month: null,
          type: null,
          comment: null,
          note: null,
          id: null,
          userFK: null,
          auditors: null
        })
      })
    this.dataArray = this.dataArray.filter(localEntry => !predicate(toMonth(localEntry)))
    this.updateListenersForCurrentMonthObservable()
  }


  public async loadLocalState(): Promise<void> {
    const localMonths = await this.userPropertiesService.getProperty(TIMEBOX_LOCAL_MONTHSUBMITTED_ENTRIES, null)
    if (localMonths != null) {
      this.dataArray = localMonths

      this.updateListenersForCurrentMonthObservable()
    }
  }

  setSynchronizationState(value: SynchronizationStatus): void {
    this.synchronizationStateSubject.next(value)
  }

  synchronizationStateChanges(): Observable<SynchronizationStatus> {
    return this.synchronizationStateSubject.asObservable()
  }

  public async postSynchronize(): Promise<void> {
    this.dataArray.forEach(localEntry => localEntry.mirrorMonth = null)
    this.updateListenersForCurrentMonthObservable()
    this.setSynchronizationState(SynchronizationStatus.IS_SYNCHRONIZED)
  }

  public async saveLocalState(): Promise<void> {
    this.userPropertiesService.setProperty(TIMEBOX_LOCAL_MONTHSUBMITTED_ENTRIES, this.dataArray).then()
  }

  isAvailableFor(user: User): boolean {
    return hasPermission(user, Permission.ProcessOtherUsersMonthsSubmittedPermission)
  }

  public synchronizeOnMonthChange(): boolean {
    return false
  }

  /* ######################   Synchronization   ###################### */

  protected async fetchRemoteData(): Promise<Month[]> {
    this.updateListenersForCurrentMonthObservable()
    const submitTypes = [SubmitType.Open, SubmitType.Submitted, SubmitType.Reopened, SubmitType.Checked];
    if (this.verifyFilterGlobalsService.verifyFunction === VerifyView.SUCHEN) {
      submitTypes.push(SubmitType.Verified)
    }
    return await this.submitMonthService.getMonthSubmittedInRangeBySubmitTypes(
      submitTypes,
      this.verifyFilterGlobalsService.selectedUSer?.id, this.verifyFilterGlobalsService.dateRange
    )
  }

  protected async mergeRemoteDataIntoLocalData(remoteMonths: any[]): Promise<void> {
    this.addRemoteMonthsToLocalStore(remoteMonths)
  }

  protected handleServerModificationResponseForModifiableObject(response: any, modifiedData: LocalMonth, prePushData: LocalMonth): void {
    // TODO: Write handle for REST response
  }

  protected async pushDataToRemote(localMonth: LocalMonth): Promise<boolean> {
    if (localMonth.mirrorMonth != null) {
      localMonth.id = localMonth.mirrorMonth.id
    }

    return await this.submitMonthService.updateMonthForUser(toMonth(localMonth)).then((response) => {
      const success = IS_RESULT_OK(response)
      localMonth.tracked = success
      if (!success) {
        const dialogMessage = 'Ein Fehler wurde vom Server zurückgegeben!\nAnscheinend wurde der Nachweis bereits angenommen oder wieder abgelehnt.\nMöchten Sie den Tab aktualisieren?'
        this.dialog.open(ConfirmDialogComponent, {
          data: {
            text: dialogMessage
          } as ConfirmDialogData
        }).afterClosed().subscribe((okPressed) => {
          if (okPressed) {
            this.userPropertiesService.deleteProperty(TIMEBOX_LOCAL_MONTHSUBMITTED_ENTRIES).then()
            window.location.reload()
            throw Error('Reloading')
          }
        })
      }

      return success
    })
  }

  protected keepAfterSynchronization(entry: LocalMonth): boolean {
    return entry.month != null
  }

  protected getDataIdentifier(data: LocalMonth): number | string {
    return data.id
  }

  private modifyLocalMonthEntry(entry: LocalMonth, month: Month): void {
    if (modifyModifiableObject({
      comment: month.comment,
      note: month.note,
      type: month.type,
      auditors: month.auditors,
    }, entry)) {
      this._modified$.next(true)
    }
  }

  private addRemoteMonthsToLocalStore(remoteMonths: Month[]): void {
    remoteMonths
      .forEach((remoteMonth) => {
        const localMonth = this.dataArray.find((month) => hashLocalMonth(month) === localMonthHashOf(remoteMonth))
        if (localMonth !== undefined) {
          localMonth.mirrorMonth = remoteMonth
        } else {
          const newLocalMonth = toLocalMonth(remoteMonth)
          this.dataArray.push(newLocalMonth)
        }
      })

    const trackedMonths = this.dataArray.filter(entry => entry.mirrorMonth != null)
    trackedMonths
      .forEach(entry => {
        mergeDataWithModifiableObject(entry.mirrorMonth, entry)
        entry.tracked = true
      })
  }

  private updateListenersForCurrentMonthObservable(): void {
    const validEntries = this.dataArray.filter(month => month.month != null)
    this.allMonthSubmittedSubject$.next(validEntries.map(toMonth))
  }

  /* ################################################################# */

}

function toLocalMonth(month: Month, tracked: boolean = true): LocalMonth {
  return {
    userFK: month.userFK,
    month: month.month,
    tracked,
    comment: {
      value: month.comment,
      modified: false
    },
    id: month.id,
    mirrorMonth: month,
    modified: false,
    note: {
      value: month.note,
      modified: false
    },
    type: {
      value: month.type,
      modified: false
    },
    auditors: {
      value: deepCopy(month.auditors),
      modified: false
    }
  }
}

function toMonth(localEntry: LocalMonth): Month {
  return {
    id: localEntry.id,
    userFK: localEntry.userFK,
    month: localEntry.month,
    type: localEntry.type.value,
    comment: localEntry.comment.value,
    note: localEntry.note.value,
    auditors: deepCopy(localEntry.auditors.value)
  }
}

// Why hash function when uuid is unique?
function hashLocalMonth(entry: LocalMonth): string {
  return `${entry.userFK}-${entry.month}`
}

function localMonthHashOf(month: Month): string {
  return hashLocalMonth(toLocalMonth(month))
}


interface LocalMonth extends ModifiableDataObject {
  id: number
  userFK: number
  month: string
  type: ModifiableField<SubmitType>
  comment: ModifiableField<string>
  note: ModifiableField<string>
  mirrorMonth: Month
  auditors: ModifiableField<UserAuditorResult[]>
}

const SERVER_RESPONSE_ALL_OK = 2

const IS_RESULT_OK: (MonthModificationResult) => boolean = response => {
  return response.success === SERVER_RESPONSE_ALL_OK
}
